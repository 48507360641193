export const MERCHANTS = [
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-2/logos/merchant-1.png'),
    destinationURL: 'https://travel.willer.co.jp/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-2/logos/merchant-2.png'),
    destinationURL: 'https://s.kosokubus.com/?ad=PDY',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-2/logos/merchant-3.png'),
    destinationURL: 'https://www.489.fm/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-2/logos/merchant-4.png'),
    destinationURL: 'https://vipliner.biz/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-2/logos/merchant-5.png'),
    destinationURL: 'https://www.busnoru.jp/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-2/logos/merchant-6.png'),
    destinationURL: 'https://www.asoview.com/',
    category: [2],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'バス' },
  { value: 2, label: '遊び体験・チケット' },
];
