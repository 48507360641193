import React from 'react';
import Layout from 'src/components/Layout/Layout';
import { Hidden } from '@material-ui/core';
import 'src/components/Layout/Layout.scss';
import { SITE_METADATA } from 'src/constants';
import TravelsSummerVol2Condition from 'src/components/Merchant/Campaign/TravelsSummerVol2/TravelsSummerVol2Condition';
import TravelsSummerVol2MerchantGrid from 'src/components/Merchant/Campaign/TravelsSummerVol2/TravelsSummerVol2MerchantGrid';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import PaymentSteps from 'src/components/Merchant/Campaign/TravelsSummerVol1/PaymentSteps';
import styles from 'src/components/Merchant/Campaign/TravelsSummerVol2/TravelsSummerVol2.module.scss';

export default function TravelsSummerVol2() {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.travelsummervol2.title,
        description: SITE_METADATA.travelsummervol2.description,
      }}
      hasNoHeader
      hasNoFooter
    >
      <div className={styles.topBannerBackground}>
        <div className={styles.topBanner} />
      </div>
      <Hidden xsDown>
        <div className={styles.backgroundDescription}>
          <div className={styles.description} />
        </div>
      </Hidden>
      <PaymentSteps />
      <TravelsSummerVol2Condition />
      <div className={styles.merchantTitle}>
        <p>対象サイト</p>
      </div>
      <TravelsSummerVol2MerchantGrid />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
