import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import SectionCampaignCondition from '../TravelsSummerVol1/SectionCampaignCondition';

const DATA = [
  {
    title: <strong>キャンペーン概要</strong>,
    content: (
      <>
        キャンペーン期間中に対象サイトの決済画面でペイディを初めて利用して6,000円（税込）以上ご利用をすると500円キャッシュバック。
        <br />
        <br />
        ※キャンペーン期間中に上記条件でご利用された方に、8月31日までにキャッシュバックを実施。8月31日ごろにペイディアプリおよびMyPaidyに反映されます。決済画面には反映されませんのでご注意ください。
      </>
    ),
  },
  {
    title: <strong>キャンペーン対象者</strong>,
    content: 'はじめてあと払い（ペイディ）を対象加盟店で利用される方',
  },
  {
    title: <strong>キャンペーン期間</strong>,
    content: '2022年7月8日（金）0:00～7月21日（木）23:59',
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・キャッシュバックは1加盟店につきお一人様1回までとさせていただきます。
        <br />
        ・キャッシュバックは、決済画面でペイディを選択できる商品の場合のみ対象です。ペイディカードの利用は対象外となります。
        <br />
        ・キャンペーン期間中、対象加盟店での「初めてのご利用」が6,000円以上（税込）の場合のみキャッシュバックの対象になります。キャンペーン期間中の「初めてのご利用」が6,000円未満の場合、仮に、2回以上のご利用合計額が6,000円以上になる場合や、または、2回目以降のご利用が6,000円以上の場合であってもキャッシュバック付与の対象にはなりませんのでご注意ください。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・キャンペーン期間のご利用に対して2022年8月31日（水）までにキャッシュバックをいたします。7月中にキャッシュバックは反映されません。
        <br />
        ・不正と判断された場合や購入のキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件は
        <OutboundLink
          href="https://terms.paidy.com/cashback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          キャッシュバック利用規約
        </OutboundLink>
        に準じます。
        <br />
        ・ペイディに関するお問い合わせは
        <OutboundLink
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディヘルプページ
        </OutboundLink>
        へご連絡ください。
      </>
    ),
  },
];

export default function TravelsSummerVol2Condition() {
  return <SectionCampaignCondition data={DATA} />;
}
